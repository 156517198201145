import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import InstagramEmbed from "react-instagram-embed"

import { Gym, Problem, SolutionConnection } from "@types"
import Breadcrumb from "@/components/Breadcrumb"
import GymCoverImage from "@/gym/components/GymCoverImage"

interface Props {
  data: {
    gym: Gym
    problem: Problem
    allSolution: SolutionConnection
  }
}

export default (props: Props) => {
  const { gym, problem, allSolution } = props.data

  const embeds = allSolution.edges.map(edge => (
    <InstagramEmbed key={edge.node.id} url={edge.node.url!} />
  ))

  return (
    <Container>
      <GymCoverImage imageName={gym.image!} />
      <Section>
        <Breadcrumb>
          <Link to="/">홈</Link>
          <Link to={`/gyms/${gym.id}`}>{gym.name}</Link>
          <Link to="#">{problem.name}</Link>
        </Breadcrumb>
        <Title>{problem.name}</Title>
      </Section>
      <Section>
        {embeds}
      </Section>
    </Container>
  )
}

export const query = graphql`
  query($gymId: String!, $problemId: String!) {
    gym: gym(id: { eq: $gymId }) {
      id
      name
      image
    }
    problem: problem(id: { eq: $problemId }) {
      id
      name
      image
      difficulty_id
    }
    allSolution: allSolution(filter: { problem_id: { eq: $problemId } }) {
      edges {
        node {
          id
          problem_id
          url
        }
      }
    }
  }
`

const Container = styled.div.attrs({ className: 'container' })`
`

const Section = styled.section.attrs({ className: 'section' })`
`

const Title = styled.h1.attrs({ className: 'title' })`
`
